import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './App.css';

function Navigation() {
  return (
    <Navbar sticky="top" id="navbar" background-color='rgba(0,0,0,0)' expand="md" className="bg-body-tertiary" data-bs-theme="dark">
      <Container>
        <Link className='text-decoration-none' to={{pathname: "/"}}><Navbar.Brand>tjh.town</Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#aboutme">About</Nav.Link>
            {/* <Nav.Link href="#travel">Travel</Nav.Link> */}
            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
